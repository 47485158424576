
import Vue from "vue";

import {
  userMapActions,
  userMapMutations,
  userMapState
} from "@/store/modules/user";
import UserMenu from "@/components/User/UserMenu.vue";

interface IData {
  didInit: boolean;
  activeIndex: string;
  error: string;
  message: string;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  disablePrimaryButton: boolean;
  loadingText: string;
}

export default Vue.extend({
  name: "singleUser",
  components: {
    UserMenu
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.matched.length === 1) {
      return next(`${to.path}/edit`);
    }
    next();
  },
  data(): IData {
    return {
      didInit: false,
      activeIndex: "info",
      error: "",
      message: "",
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The user you are looking for cannot be found.",
      disablePrimaryButton: false,
      loadingText: ""
    };
  },
  created: function() {
    const { userId } = this;
    this.getUser(userId)
      .then(() => {
        this.SET_EDIT_USER(this.userId);
      })
      .catch(e => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(e);
      })
      .finally(() => {
        this.didInit = true;
      });
  },
  destroyed() {
    this.SET_EDIT_USER("");
  },
  methods: {
    ...userMapActions(["getUser", "restoreUser"]),
    ...userMapMutations(["SET_EDIT_USER"]),
    updateUser(): void {
      const ref: any = this.$refs.singleUser;
      if (ref.update) {
        ref.update();
      }
    },
    updatePassword(): void {
      const ref: any = this.$refs.singleUser;
      if (ref.update) {
        ref.viewPassword();
      }
    },
    approveUser(): void {
      const ref: any = this.$refs.singleUser;
      if (ref.update) {
        ref.activateUser();
      }
    },
    deleteUser(): void {
      const ref: any = this.$refs.singleUser;
      if (ref.update) {
        ref.deleteCallback();
      }
    },
    unbanUser(): void {
      const ref: any = this.$refs.singleUser;
      if (ref.update) {
        ref.unbanCallback();
      }
    },
    restrictUser(): void {
      const ref: any = this.$refs.singleUser;
      if (ref.update) {
        ref.restrictCallback();
      }
    },
    derestrictUser(): void {
      const ref: any = this.$refs.singleUser;
      if (ref.update) {
        ref.destrictCallback();
      }
    },
    async restoreDeletedUser(): Promise<void> {
      try {
        this.loadingText = "Restoring deleted user. Please wait...";
        await this.restoreUser(this?.editing?._id || "");
        this.$router
          .push(`/${this.$getCurrentUser?.role}/users`)
          .catch(() => {});

        this.$appNotifySuccess("User restored");
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.$modal.hide("restoreUserModal");
      }
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "update":
          this.updateUser();
          break;
        case "update-password":
          this.updatePassword();
          break;
        case "approve":
          this.approveUser();
          break;
        case "delete":
          this.deleteUser();
          break;
        case "unban":
          this.unbanUser();
          break;
        case "restrict":
          this.restrictUser();
          break;
        case "derestrict":
          this.derestrictUser();
          break;
        case "restore":
          this.$modal.show("restoreUserModal");
          break;
        case "cancel":
          this.$router.go(-1);
          break;
      }
    }
  },
  watch: {
    "$refs.singleUser.disablePrimaryButton": {
      handler(val) {
        this.disablePrimaryButton = val;
      }
    }
  },
  computed: {
    ...userMapState(["makingApiRequest", "editing"]),
    userEditActions(): any[] {
      const isOnEditPath = this.$route.fullPath.endsWith("/edit");
      const actions = [
        {
          title: "Update Password",
          command: "update-password",
          loading: this.makingApiRequest
        },
        {
          title: "Cancel",
          command: "cancel"
        }
      ];
      if (this.$isCurrentUserAdmin) {
        if (this.editing && this.editing.isBanned) {
          actions.push({
            title: "Unban User",
            command: "unban",
            loading: this.makingApiRequest
          });
        }
        if (this.editing && !this.editing.isRestricted) {
          actions.push({
            title: "Restrict User",
            command: "restrict"
          });
        }
        if (this.editing && this.editing.isRestricted) {
          actions.push({
            title: "Derestrict User",
            command: "derestrict"
          });
        }
      }
      if (this.editing && this.editing.status !== "active") {
        actions.push({
          title: "Approve",
          command: "approve",
          loading: this.makingApiRequest
        });
      }
      if (this.editing?.deleted) {
        actions.push({
          title: "Restore User",
          command: "restore",
          loading: this.makingApiRequest
        });
      } else {
        actions.push({
          title: "Delete User",
          command: "delete",
          loading: this.makingApiRequest
        });
      }
      const actionSorted = actions.sort((a: any, b: any): any => {
        return a.title.toUpperCase() < b.title.toUpperCase()
          ? -1
          : a.title.toUpperCase() < b.title.toUpperCase()
          ? 1
          : 0;
      });
      return isOnEditPath &&
        (this.$isCurrentUserAdmin || this.$isCurrentUserAgencyAdmin)
        ? actionSorted
        : [];
    },
    actionButtonSecondary(): any {
      return this.userEditActions.length
        ? {
            text: "Actions",
            key: "actions",
            subItems: this.userEditActions
          }
        : {};
    },
    saveButton(): any {
      const isOnEditPath = this.$route.fullPath.endsWith("/edit");
      return isOnEditPath &&
        (this.$isCurrentUserAdmin ||
          this.$isCurrentUserAgencyAdmin ||
          this.$isCurrentUserSubAdmin)
        ? {
            text: "Update",
            key: "update",
            items: [{}],
            loading: this.makingApiRequest,
            disabled: this.disablePrimaryButton
          }
        : {};
    },
    usersRoute(): string {
      return this.$isCurrentUserAgencyAdmin || this.$isCurrentUserSubAdmin
        ? "/agencyAdmin/users"
        : "/admin/users";
    }
  }
});
